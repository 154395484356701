// Callouts.
.callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: none;
    border-left-width: .25rem;
    border-radius: $border-radius;
    border-left-color: #343a40;

    table {
        margin: 0;
    }
}

.callout-primary {
    border-left-color: #007bff;
}

.callout-secondary {
    border-left-color: #6c757d;
}

.callout-success {
    border-left-color: #28a745;
}

.callout-danger {
    border-left-color: #dc3545;
}

.callout-warning {
    border-left-color: #f0ad4e;
}

.callout-info {
    border-left-color: #17a2b8;
}

.callout-dark {
    border-left-color: #343a40;
}

.callout-light {
    border-left-color: #f8f9fa;
}


// Block text with ellipsis.
.text-block {
  hyphens: auto;
  line-height: 1.2em;
  margin-right: -1em;
  max-height: 3.6em;
  overflow: hidden;
  overflow-wrap: break-word;
  padding-right: 1em;
  position: relative;
  text-align: justify;
  word-break: break-word;
  word-wrap: break-word;
}


// Extra widths.
.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-35 {
  width: 35% !important;
}

.w-90 {
  width: 90% !important;
}


// Rounded corners.
.rounded-top-lg {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.rounded-lg {
  border-radius: $border-radius;
}

.rounded-all-but-top-left-lg {
  border-radius: $border-radius;
  border-top-left-radius: 0;
}

.rounded-all-but-top-right-lg {
  border-radius: $border-radius;
  border-top-right-radius: 0;
}


// Border widths.
.border-2 {
  border-width: 2px !important;
}


// Muted background colors.
.bg-primary-muted {
  background: #77b9ff;
}

.bg-warning-muted {
  background: #f9d977;
}

.bg-success-muted {
  background: #8ce2a0;
}

.bg-danger-muted {
  background: #f2a4ac;
}

.bg-secondary-muted {
  background: #d2d6db;
}

.bg-gray {
  background: #eaeaea;
}

div.list-as-two-column-table {
  display: grid;
  grid-auto-columns: max-content 1fr;
  grid-template-areas: "a a";
  .t-row {
    display: contents;
    & > :nth-child(1) {
      padding-left: .5rem;
    }
    & > :nth-child(2) {
      padding-left: 1.5rem;
      padding-right: .5rem;
    }
  }
  &.striped .t-row:nth-child(2n+1) > *,
  &:not(.striped) .t-row:hover > * {
    background-color: #0000000d;
  }
}

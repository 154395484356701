.search-form-group {
  position: relative;
}

.inline-search-input-group {
  z-index: 6;
}

.inline-search-container {
  position: absolute;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px $dark;
}

.suggestion-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.suggestion {
  margin: 0 .5rem;
}

.suggestion__selected {
  background-color: $primary;
  border-radius: $border-radius;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}


.hints-box, .suggestions-box {
  border: 1px solid rgb(206, 212, 218);
  border-radius: $border-radius;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  overflow-y: hidden;
  padding-left: 0;
}

.hint-list {
  list-style: none;
}

.search-input {
  height: 2.5rem !important;
  padding-top: .5rem;
  z-index: 6 !important;
}

.search-button {
  z-index: 6 !important;
}

// Blackbar fix.
.masthead-black-bar .row {
  max-width: none;
  display: block;
  margin: 0 auto !important;
}

.expanded {
  max-width: none;
}


// EMBL Popover close 'X' fix.
.embl-bar .row .padding-top-medium {
  text-align: right;
}

// EMBL Popover fix.
.embl-bar .row.padding-bottom-medium {
  max-width: 80rem;
}

// Search bar fix.
#search-bar .padding-bottom-medium {
  padding: 0;
}

#global-search {
  width: 50%;
  margin: 0 auto 1rem auto;

  fieldset {
    border: none;
  }
}

#global-search .input-group-field {
  @extend .form-control;
}

#global-search .input-group-button .button {
  @extend .btn;
  @extend .btn-light;
}

// Search bar close 'X' fix.
#search-bar .row .padding-top-medium {
  text-align: right;

  .close-button {
    font-weight: bold;
    color: #eee;
  }
}

// Masthead fixes.
.masthead {
  background-color: $primary;
}

.menu li a.active {
  background-color: $light;
  color: $dark !important;

  &:hover {
    color: $light !important;
  }
}

nav {
  width: 100%;
}

nav .menu {
  width: 100%;
}


// Sticky placeholder to avoid jumps when sticky navbar becomes fixed.
.sticky-placeholder {
  width: 100%;
  height: 39px;
}